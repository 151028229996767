import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Determine API base URL based on environment
const API_BASE_URL = process.env.NODE_ENV === 'production' 
  ? 'https://healthcare.visionkraftconsulting.com/api' 
  : 'http://localhost:3025/api';

const BillingCalculator = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [hourlyRate, setHourlyRate] = useState(null);
  const [shifts, setShifts] = useState([]);
  const [result, setResult] = useState(null);
  const [error, setError] = useState('');
  const [file, setFile] = useState(null);
  const [manualEntryMode, setManualEntryMode] = useState(false);

  // Fetch clients from the database
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/clients`);
        const clientData = Array.isArray(response.data) ? response.data : response.data.clients;

        if (Array.isArray(clientData)) {
          setClients(clientData);
        } else {
          console.error("Unexpected API response format:", response.data);
          setClients([]);
        }
      } catch (err) {
        console.error('Failed to fetch clients:', err.message);
        setClients([]);
        setError('Failed to fetch clients. Please try again.');
      }
    };

    fetchClients();
  }, []);

  // Handle client selection and fetch hourly rate
  const handleClientSelection = async (clientId) => {
    setSelectedClient(clientId);
    setResult(null);

    if (!clientId) {
      setHourlyRate(null);
      return;
    }

    try {
      const response = await axios.get(`${API_BASE_URL}/clients/${clientId}`);
      const rate = response.data.hourly_rate ? Number(response.data.hourly_rate) : 0;
      setHourlyRate(rate);
    } catch (err) {
      console.error('Failed to fetch hourly rate:', err.message);
      setError('Failed to fetch hourly rate.');
      setHourlyRate(null);
    }
  };

  // Handle file selection
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Upload file and process timesheet
  const uploadAndProcessFile = async () => {
    if (!file) {
      setError('Please upload a timesheet file.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(`${API_BASE_URL}/billing/process-timesheet`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      const { extractedShifts } = response.data;
      setShifts([...shifts, ...extractedShifts]);
      setError('');
    } catch (err) {
      console.error('Failed to process the timesheet:', err.message);
      setError('Failed to process the timesheet. Please try again.');
    }
  };

  // Toggle manual entry mode
  const toggleManualEntryMode = () => {
    setManualEntryMode(!manualEntryMode);
  };

  // Add a manual shift
  const addShift = () => {
    setShifts([...shifts, { date: '', timeIn: '', timeOut: '' }]);
  };

  // Update shift details
  const updateShift = (index, field, value) => {
    const updatedShifts = shifts.map((shift, i) =>
      i === index ? { ...shift, [field]: value } : shift
    );
    setShifts(updatedShifts);
  };

  // Validate shifts before submission
  const validateShifts = () => {
    for (let i = 0; i < shifts.length; i++) {
      const shift = shifts[i];
      if (!shift.date || !shift.timeIn || !shift.timeOut) {
        setError(`Shift ${i + 1}: Please fill out all fields.`);
        return false;
      }

      const timeIn = new Date(`${shift.date}T${shift.timeIn}`);
      const timeOut = new Date(`${shift.date}T${shift.timeOut}`);
      if (timeIn >= timeOut) {
        setError(`Shift ${i + 1}: Time In must be earlier than Time Out.`);
        return false;
      }
    }
    return true;
  };

  // Calculate billing hours
  const calculate = async () => {
    if (!selectedClient) {
      setError('Please select a client.');
      return;
    }

    if (shifts.length === 0) {
      setError('Please add at least one shift.');
      return;
    }

    if (!validateShifts()) {
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/billing/calculate`, {
        shifts,
        clientId: selectedClient,
      });

      setResult(response.data);
      setError('');
    } catch (err) {
      console.error('Failed to calculate billing hours:', err.message);
      setError('Failed to calculate billing hours. Please try again.');
    }
  };

  return (
    <div className="container">
      <h2>Billing Calculator</h2>

      {/* Client Selection Dropdown */}
      <div className="mb-3">
        <label htmlFor="clientDropdown" className="form-label">Select Client</label>
        <select
          id="clientDropdown"
          className="form-select"
          value={selectedClient}
          onChange={(e) => handleClientSelection(e.target.value)}
        >
          <option value="">-- Select a Client --</option>
          {clients.map((client) => (
            <option key={client.id} value={client.id}>
              {client.first_name} {client.last_name}
            </option>
          ))}
        </select>
      </div>

      {/* Display Hourly Rate */}
      {hourlyRate !== null && (
        <div className="mb-3">
          <label className="form-label">Hourly Rate: </label>
          <span className="fw-bold"> ${hourlyRate.toFixed(2)} / hour</span>
        </div>
      )}

      {/* File Upload Section */}
      {!manualEntryMode && (
        <div className="mb-3">
          <label htmlFor="timesheetFile" className="form-label">Upload Timesheet</label>
          <input
            type="file"
            id="timesheetFile"
            className="form-control mb-2"
            onChange={handleFileChange}
          />
          <button className="btn btn-secondary mb-3" onClick={uploadAndProcessFile}>
            Process Timesheet
          </button>
        </div>
      )}

      {/* Toggle Manual Entry Mode */}
      <button className="btn btn-outline-primary mb-3" onClick={toggleManualEntryMode}>
        {manualEntryMode ? 'Switch to File Upload' : 'Switch to Manual Entry'}
      </button>

      {/* Display Uploaded or Manual Shifts */}
      {manualEntryMode && (
        <div>
          {shifts.map((shift, index) => (
            <div key={index} className="mb-3">
              <input
                type="date"
                value={shift.date}
                onChange={(e) => updateShift(index, 'date', e.target.value)}
                className="form-control mb-2"
              />
              <input
                type="time"
                value={shift.timeIn}
                onChange={(e) => updateShift(index, 'timeIn', e.target.value)}
                className="form-control mb-2"
              />
              <input
                type="time"
                value={shift.timeOut}
                onChange={(e) => updateShift(index, 'timeOut', e.target.value)}
                className="form-control"
              />
            </div>
          ))}
          <button className="btn btn-primary mb-3" onClick={addShift}>
            Add Manual Shift
          </button>
        </div>
      )}

      {/* Calculate Billing Button */}
      <button className="btn btn-success mb-3" onClick={calculate}>
        Calculate Billing
      </button>

      {/* Display Results */}
      {result && (
        <div className="alert alert-info">
          <p>Total Hours: {result.totalHours}</p>
          <p>Total Billing: ${result.totalBilling}</p>
        </div>
      )}
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default BillingCalculator;